import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['pandemicCopingWorries1', 'pandemicCopingWorries2', 'pandemicCopingWorries3', 'pandemicCopingWorries4', 'pandemicReflection'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Typography = makeShortcode("Typography");
const ImageAccordion = makeShortcode("ImageAccordion");
const Grid = makeShortcode("Grid");
const SaveTextField = makeShortcode("SaveTextField");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "coping-with-worries-and-uncertainties"
    }}>{`Coping with worries and uncertainties`}</h2>
    <p>{`For teens, the pandemic is more than just a disruption to their normal routine. It can be very unsettling for young people when the world around them is constantly changing and out of their control. Some teens may be particularly worried about the impact of the pandemic on their lives now and into the future.`}<br />{`So how can you help them manage their worries at this time?`}</p>
    <b>Consider the following situation</b>
    <p>{`Bron, 15, has been worried about how the pandemic may affect her final years of school and dream of becoming a chef. She plans to start a TAFE hospitality course during her Year 11 studies next year. Bron was relieved when restrictions eased a few months ago. She is particularly excited to have her 16th birthday party this weekend, especially as she spent her last birthday in lockdown.
You are at home with Bron when she hears the news of another lockdown starting tonight. She storms into the room in a rage, shouting `}<i>{`“Arghhhhh this is ridiculous!! MORE lockdown! I’m so sick of it all! Every time I plan something, it just gets ruined. What’s the point?! It’s just gonna be the same as last year all over again, stuck at home, no friends, no party, no TAFE course, it’s SO UNFAIR.”`}</i></p>
    <Typography mdxType="Typography">
  <b>
    How do you think you would respond if this was your teen? Click each option below to see how your response can
    influence your teen.
  </b>
    </Typography>
    <br />
    <ImageAccordion id="pandemicCopingWorries1" index="1" title="Acknowledge their worries and empathise with them." description={<div>
      This is a great response. It’s important to acknowledge how your teen is feeling and let them know their worries
      are valid, even if you don’t share their concerns. Express empathy when your teen is feeling a strong emotion.
      Responding in this way helps your teen to feel understood and accepted. They are more likely to open up to you
      about how they are feeling, in this situation and in the future. When teens are feeling strong emotions, often
      what they need most from their parents is emotional support, rather than offering solutions to their problem.
    </div>} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicCopingWorries2" index="2" title="Let them know you share their worries. Join them in discussing how stressful it is." description={<div>
      Although it might be tempting to share your own worries with your teen in an effort to show you understand,
      responding in this way is likely to increase your own and your teen’s anxiety, leaving you both feeling worse.
      When your teen is feeling a strong emotion, try to remain calm when talking with them. Acknowledge that the
      uncertainty of the pandemic can be unsettling, while setting an example for your teen by showing that you can
      manage your own anxiety. Remember to seek your own support if you are struggling to manage your anxiety or mental
      health.
    </div>} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicCopingWorries3" index="3" title="Tell them not to stress, it’s not that bad." description={<div>
      Even if you feel your teen is overreacting or you disagree with their concerns, avoid dismissing or downplaying
      how they are feeling. Responding in this way can make your teen feel that their emotions are wrong or they are bad
      for having them. They may feel hurt and rejected. This will discourage them from opening up to you next time they
      are upset. Instead, try to show your teen that it’s okay to feel strong emotions, and that you are there to
      support them.
    </div>} mdxType="ImageAccordion" />
    <ImageAccordion id="pandemicCopingWorries4" index="4" title="Work together to help your teen figure out what is in their control and what is not." description={<div>
      This is a great response if your teen seems open to problem-solving. In times of uncertainty, it can be helpful to
      focus on what we can control. This is a strategy you can teach your teen during the pandemic. Try to set a
      positive example by showing your teen how you accept what you can’t control, and focus your attention on what you
      can control. If your teen is worried about a situation, you could suggest brainstorming with them what they can
      and can’t control. If your teen is very upset, you might first need to provide emotional support (see the first
      response above) and suggest this activity after they’ve calmed down.
    </div>} mdxType="ImageAccordion" />
    <br />
    <br />
    <Typography color="primary" variant="h5" mdxType="Typography">
  <b>Reflection</b>
    </Typography>
    <p>{`Having `}<b>{`completed this activity`}</b>{`, how do you think you might respond differently next time your teen talks about how they’re feeling?`}</p>
    <Grid container justify="center" spacing={2} mdxType="Grid">
  <SaveTextField id="pandemicReflection" variant="outlined" fullWidth={true} multiline={true} label="Insert text here" mdxType="SaveTextField" />
    </Grid>
    <SingleImage smallGridSize={8} gridSize={5} src="/images/m2/19-missing-milestones.png" alt="Missing milestones post-it note" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      